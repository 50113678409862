/********** Add Your Global CSS Here **********/
h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
li,
input,
textarea,
span,
div,
html,
body,
html a {
  margin-bottom: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
}
a {
  color: #0c5387 !important;
}
.ant-pagination-item-active a {
  color: #ffffff !important;
}
.ant-pagination-item-active {
  background-color: #1d73b3 !important;
  border-color: #1d73b3 !important;
}
.ant-btn.ant-btn-primary {
  background-color: #1d73b3 !important;
  border-color: #1d73b3 !important;
}
.ant-switch-checked {
  background-color: #1d73b3 !important;
}

.scrollbar-track-y,
.scrollbar-thumb-y {
  width: 5px !important;
}

.scrollbar-track-x,
.scrollbar-thumb-x {
  height: 5px !important;
}

.scrollbar-thumb {
  border-radius: 0 !important;
}

.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow:after,
.ant-popover-placement-bottomLeft
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-bottomRight
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow:after,
.ant-popover-placement-topLeft
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-topRight
  > .ant-popover-content
  > .ant-popover-arrow:after {
  left: 0;
  margin-left: -4px;
}

/********** Add Your Global RTL CSS Here **********/
/* Popover */
html[dir="rtl"] .ant-popover {
  text-align: right;
}

/* Ecommerce Card */
html[dir="rtl"] .isoCardInfoForm .ant-input {
  text-align: right;
}

/* Modal */
html[dir="rtl"] .has-success.has-feedback:after,
html[dir="rtl"] .has-warning.has-feedback:after,
html[dir="rtl"] .has-error.has-feedback:after,
html[dir="rtl"] .is-validating.has-feedback:after {
  left: 0;
  right: auto;
}

html[dir="rtl"] .ant-modal-close {
  right: inherit;
  left: 0;
}

html[dir="rtl"] .ant-modal-footer {
  text-align: left;
}

html[dir="rtl"] .ant-modal-footer button + button {
  margin-left: 0;
  margin-right: 8px;
}

html[dir="rtl"] .ant-confirm-body .ant-confirm-content {
  margin-right: 42px;
}

html[dir="rtl"] .ant-btn > .anticon + span,
html[dir="rtl"] .ant-btn > span + .anticon {
  margin-right: 0.5em;
}

html[dir="rtl"] .ant-btn-loading span {
  margin-left: 0;
  margin-right: 0.5em;
}

html[dir="rtl"]
  .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline) {
  padding-left: 25px;
  padding-right: 29px;
}

html[dir="rtl"]
  .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline)
  .anticon {
  margin-right: -14px;
  margin-left: 0;
}

/* Confirm */
html[dir="rtl"] .ant-modal.ant-confirm .ant-confirm-body > .anticon {
  margin-left: 16px;
  margin-right: 0;
  float: right;
}

html[dir="rtl"] .ant-modal.ant-confirm .ant-confirm-btns {
  float: left;
}

html[dir="rtl"] .ant-modal.ant-confirm .ant-confirm-btns button + button {
  margin-right: 10px;
  margin-left: 0;
}

/* Message */
html[dir="rtl"] .ant-message .anticon {
  margin-left: 8px;
  margin-right: 0;
}

/* Pop Confirm */
html[dir="rtl"] .ant-popover-message-title {
  padding-right: 20px;
  padding-left: 0;
}

html[dir="rtl"] .ant-popover-buttons {
  text-align: left;
}

/* Notification */
html[dir="rtl"]
  .ant-notification-notice-closable
  .ant-notification-notice-message {
  padding-left: 24px;
  padding-right: 0;
}

html[dir="rtl"]
  .ant-notification-notice-with-icon
  .ant-notification-notice-message,
html[dir="rtl"]
  .ant-notification-notice-with-icon
  .ant-notification-notice-description {
  margin-right: 48px;
}

html[dir="rtl"] .ant-notification-notice-close {
  right: auto;
  left: 16px;
}

html[dir="rtl"] .ant-notification-notice-with-icon {
  left: 0;
}

/* Dropzone */
html[dir="rtl"] .dz-hidden-input {
  display: none;
}

.cbtContentWrapper {
  display: block;
  position: relative;
  width: 100%;
  min-height: 100vh;
  height: auto;
  text-align: center;
}

.cbtContentWrapper h1 {
  margin: 10px;
}

.cbtContent {
  display: block;
  width: 100%;
  height: 100px;
}

.cbtSignInPage {
  display: block;
  position: relative;
  width: 100%;
  min-height: 100vh;
  height: auto;
}
.cbtSignInPage .cbtLoginContent {
  display: block;
  width: 300px;
  height: auto;
  margin: 20px auto;
}
.add-icon {
  position: relative;
  height: 50px;
  padding: 0px;
  float: right;
}
.add-icon i {
  position: absolute;
  display: block;
  font-size: 24px;
  cursor: pointer;
  color: blue;
  top: -46px;
  float: right;
  right: 5px;
}
.isomorphicContent .ant-breadcrumb {
  display: block;
  padding: 10px;
  background: #e9e9e9;
  text-decoration: underline;
}
.isomorphicContent .ant-breadcrumb-separator {
  margin: 0 3px;
}
.ant-modal-content .ant-modal-header {
  background: #4384a2;
  color: #ffffff;
}
.ant-modal-content .ant-modal-title {
  color: #ffffff;
  font-weight: 800;
}
.ant-modal-content .anticon.anticon-close.ant-modal-close-icon {
  color: #ffffff;
  font-weight: 800;
}

.isoSortableCardsContainer ul {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
}
.row-img img {
  width: 45px;
}
.ant-table .ant-table-body .ant-table-row .title-col {
  /* max-width: 250px; */
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: normal;
}
.ant-row.import {
  margin-bottom: 10px;
}
.ant-row.color {
  margin-top: 10px;
}
.ant-row.color ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.ant-row.color ul li {
  display: block;
  margin: 10px 0;
}
.ant-row.color ul li span {
  content: "";
  display: inline-block;
  width: 30px;
  height: 10px;
  margin-right: 10px;
}
.ant-row.color ul li.hasErr span {
  background: #ff0000;
}
.ant-row.color ul li.hasDup span {
  background: #f56913;
}
.ant-row.color ul li.hasExst span {
  background: #066ef9;
}
.ant-table .ant-table-body .ant-table-row .title-col .missing {
  color: #ff0000;
}
.ant-table .ant-table-body .ant-table-row .title-col .duplicate {
  color: #f56913;
}
.ant-table .ant-table-body .ant-table-row .title-col .db-exists {
  color: #066ef9;
}
.ant-table .ant-table-body .ant-table-row .caps {
  text-transform: capitalize;
}
.ant-table .ant-table-body .ant-table-row .desc-col {
  max-width: 350px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: normal;
}
.ant-table .ant-table-body .ant-table-row .lg-col {
  max-width: 550px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: normal;
}
.add-file.ant-form .ant-form-item .ant-form-item-label {
  /* width: 100px; */
  text-align: left;
  line-height: 22px;
}
.ant-select-selection--multiple .ant-select-selection__placeholder {
  margin-left: 0px !important;
}

.ant-row.psm {
  margin-bottom: 1px;
}
.ant-row.import {
  margin-top: 25px;
}
.downloadLink {
  display: block;
  margin: 0px 0 20px 0;
  text-decoration: underline;
}
.sample-csv {
  display: inline-block;
  float: right;
  font-size: 14px;
}

.filter {
  position: relative;
  font-size: 24px;
  cursor: pointer;
  color: #0c5387;
  float: right;
  right: 50px;
  margin-top: 5px;
}
.groupModal {
  width: 750px !important;
}
.groupUserSelector {
  width: 500px !important;
}
.ant-form-item{
  /* margin-bottom:0px !important; */
  padding-bottom:0px !important
}
.ant-form-vertical .ant-form-item-label{
  padding-bottom:0px !important

}
